@import '@assets/styles/abstracts';

.TravelstoryList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  .HighlightedItem {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    @include media-breakpoint-down(sm) {
      grid-column-end: 1;
      grid-row-end: 1;
    }
  }

  .NoStories {
    font-size: $font-size-xl;
    font-weight: $font-weight-semibold;
    grid-column-start: 1;
    grid-column-end: 6;
    text-align: center;
  }
}
