@import '@assets/styles/abstracts';

.IconHeader {
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid color(grey);

  .Icon {
    position: relative;
    color: color(primary);
    margin: auto px(1.5) auto 0;
  }

  .Title {
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
  }

  &.sm {
    padding-bottom: px(2);

    .Icon {
      width: $font-size-h3;
      height: $font-size-h3;
    }

    .Title {
      font-size: $font-size-h3;
    }
  }

  &.md {
    padding-bottom: px(2);

    .Icon {
      width: $font-size-h2;
      height: $font-size-h2;

      margin: 0;
      margin-right: px(2);
    }

    .Title {
      font-size: $font-size-h2;
      margin: auto 0;
    }
  }

  &.lg {
    padding-bottom: px(2);

    .Icon {
      width: $font-size-h2;
      height: $font-size-h2;

      margin: 0;
      margin-right: px(3);
    }

    .Title {
      font-size: $font-size-h2;
      margin: auto 0;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: px(2) !important;

    .Icon {
      font-size: $font-size-xl !important;
      width: $font-size-h5 !important;
      height: $font-size-h5 !important;
    }

    .Title {
      font-size: $font-size-h5 !important;
    }
  }
}
