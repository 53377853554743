@import '@assets/styles/abstracts';

.HighlightedNewsListItem {
  display: flex;
  flex-direction: row;

  .Thumbnail {
    position: relative;
    display: flex;

    align-items: center;
    width: 46%;

    img {
      object-fit: cover;
    }
  }

  .Content {
    box-sizing: border-box;
    padding: 8px 8px 4px 28px;

    width: 54%;

    .Title {
      overflow: hidden;

      font-size: $font-size-lg;
      line-height: 1.1;

      margin-top: px(1);
      margin-bottom: px(0);

      @include max-lines(4, 1.09);
    }
  }
}
