@import '@assets/styles/abstracts';

.HighlightedNewsList {
  .HighlightedItemWrapper {
    margin-bottom: px(2);

    .HighlightedItem {
      height: 100%;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 6px;
    }
  }

  .ListItems {
    .NewsListItem {
      margin-bottom: px(2);
    }
  }
}
