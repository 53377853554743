@import '@assets/styles/abstracts';

.AnchorText {
  line-height: 0;
  color: transparent;
  user-select: none;
}

.Tip {
  @include aspect(100%, 'tip');

  @include transition(box-shadow, md);

  @include media-breakpoint-up(lg) {
    &:hover {
      @include box-shadow(primary);

      .InfoView {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

  &.Secondary {
    &:hover {
      @include box-shadow(secondary);
    }

    .Wrapper {
      background-color: color(secondary);

      a {
        color: color(primary);
      }
    }
  }

  .Wrapper {
    padding: px(3);
    flex-direction: column;
    background-color: color(primary);
    justify-content: space-between;
    display: flex;
    height: 100%;

    @include media-breakpoint-down(sm) {
      padding: px(2);
    }

    p {
      color: color(white);
    }

    a {
      color: color(secondary);
    }
  }

  .DefaultView {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .Thumbnail {
      object-fit: cover;
    }

    .ThumbnailOverlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background: linear-gradient(to bottom, color(transparent) 50%, rgba(0, 0, 0, 0.59) 100%);
    }
  }

  .Title {
    line-height: 1.25;
    z-index: 1;
    color: white;
    position: relative;
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    margin-bottom: px(1);

    @include media-breakpoint-down(sm) {
      font-size: $font-size-md;
    }
  }

  .LargeTitle {
    font-size: $font-size-h3;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-md;
    }
  }

  .InfoView {
    transform: translate3d(0, 30px, 0);
    opacity: 0;
    height: 100%;
    transition: all ease-out 0.2s;
    z-index: 2;
  }

  .InfoTextWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
  }

  .Button {
    width: 100%;
  }

  .Excerpt {
    flex: 1 1 auto;
    color: color(white);
    overflow: hidden;
    margin-bottom: px(3);
    font-weight: $font-weight-light;
  }

  .Gradient {
    position: absolute;
    bottom: px(3);
    left: 0;
    right: 0;
    height: 32px;
    z-index: 2;
  }

  .GradientPrimary {
    background: linear-gradient(color(transparent, primary), color(primary));
  }

  .GradientSecondary {
    background: linear-gradient(color(transparent, secondary), color(secondary));
  }
}
