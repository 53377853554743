@import '@assets/styles/abstracts';

.FacebookLikeButton {
  display: flex;

  > div {
    margin: auto 0;
    height: 20px;
  }
}
