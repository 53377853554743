@import '@assets/styles/abstracts';

.SearchBlock {
  position: relative;
  overflow: hidden;

  .Background {
    z-index: -1;
    object-fit: cover;
  }

  .Content {
    display: flex;

    flex-direction: column;

    align-items: center;
    text-align: center;

    padding: px(16) px(4);

    @include media-breakpoint-down(sm) {
      padding: px(8) px(3);
    }

    .Title {
      font-size: $font-size-xxxl;
      font-weight: $font-weight-black;
      line-height: $headings-line-height;

      margin: 0;
      margin-bottom: px(2);

      color: color(primary);

      @include media-breakpoint-down(sm) {
        font-size: $font-size-h2;
      }
    }

    .Input {
      width: 480px;
      max-width: 100%;
    }
  }
}
