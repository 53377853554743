@import '@assets/styles/abstracts';

.Tag {
  display: inline-block;
  background-color: color(secondary);
  color: color(white);

  text-transform: uppercase;

  font-family: $font-family-alt;
  font-weight: $font-weight-bold;

  &.sm {
    padding: px(1) px(4);
  }

  &.xl {
    padding: px(1.5) px(4);
    font-size: $font-size-h3;
  }

  @include media-breakpoint-down(sm) {
    padding: px(1) px(4) !important;
    font-size: $font-size-base !important;
  }
}
