@import '@assets/styles/abstracts';

.NewsList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-column-gap: px(1.5);

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  .NewsListItem {
    margin-bottom: px(4);
  }

  .NoArticles {
    font-size: $font-size-xl;
    font-weight: $font-weight-semibold;
    grid-column-start: 1;
    grid-column-end: 6;
    text-align: center;
  }
}
