@import '@assets/styles/abstracts';

.MeetCard {
  .Thumbnail {
    position: relative;

    .ImageWrapper {
      @include aspect(100%, '16:9');
    }
  }

  .Content {
    height: 100%;
    padding: px(3) px(4) 0;

    .Category {
      text-transform: uppercase;
      font-weight: $font-weight-medium;

      margin-bottom: px(2);
    }

    .Title {
      font-size: rem(16px);
      font-weight: $font-weight-black;
      text-transform: uppercase;
      color: color(primary);

      margin-bottom: px(1);

      @include transition(color, sm);

      @include max-lines(2, $headings-line-height);

      @include media-breakpoint-only(lg) {
        font-size: $font-size-sm;
      }

      @include media-breakpoint-only(md) {
        font-size: rem(15px);
      }
    }

    .Description {
      p {
        font-size: $font-size-sm;
      }

      @include max-lines(5);
    }

    @include media-breakpoint-only(lg) {
      padding: px(2) px(3) 0;
    }
  }

  .Footer {
    padding: 0 px(4) px(3);

    margin-top: px(2);

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    @include media-breakpoint-only(lg) {
      padding: px(2) px(3) 0;

      font-size: $font-size-sm;
    }
  }
}
