@import '@assets/styles/abstracts';

.HighlightedNewsItem {
  @include aspect(100%, '3:2');

  height: 100%;

  img {
    object-fit: cover;
  }

  .ContentWrapper {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .Content {
      position: absolute;

      bottom: 0;
      left: 0;

      padding: px(4);

      .Title {
        color: color(white);

        font-size: $font-size-h2;

        text-transform: uppercase;

        margin-bottom: px(1);

        @include media-breakpoint-down(sm) {
          font-size: $font-size-h3;
        }

        @include media-breakpoint-down(xs) {
          font-size: $font-size-h4;
        }
      }
    }
  }
}

.InnerClass {
  height: 100%;
}
