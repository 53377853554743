@import '@assets/styles/abstracts';

.Article {
  .Thumbnail {
    position: relative;
    .ImageWrapper {
      @include aspect(100%, '16:9');

      img {
        object-fit: cover;
      }

      .ImageWrapper {
        @include aspect(100%, '4:3');
      }
    }

    .Tag {
      position: absolute;
      left: 0;
    }
  }

  .Content {
    .Head {
      display: flex;
      justify-content: space-between;
    }

    .Category {
      text-transform: uppercase;
      font-weight: $font-weight-medium;
    }

    .Title {
      @include transition(color, sm);

      @include max-lines(2, $headings-line-height);
    }
  }

  .Footer {
    display: flex;
    justify-content: flex-end;

    .LikeButton {
      margin-right: -12px;
    }
  }

  &.sm {
    .Thumbnail {
      .ImageWrapper {
        @include aspect(100%, '16:9');
      }

      .Tag {
        bottom: 30px;
      }
    }

    .Content {
      padding: px(2) px(1) px(2) px(4);

      .Category {
        margin-bottom: 0;
        font-size: $font-size-md;
      }

      .Title {
        font-size: $font-size-h3;
        margin-bottom: px(1);

        @include max-lines(3, $headings-line-height);
      }

      .Excerpt {
        font-size: $font-size-base;
        @include max-lines(2);
      }
    }
  }

  &.xl {
    .Thumbnail {
      .ImageWrapper {
        @include aspect(100%, '4:3');
      }

      .Tag {
        bottom: 45px;
      }
    }

    .Content {
      padding: px(3) px(2) px(3) px(4);

      .Category {
        font-size: $font-size-h3;
        margin-bottom: px(3);
      }

      .Title {
        font-size: $font-size-h2;
        margin-bottom: px(2);
      }

      .Excerpt {
        font-size: $font-size-h3;
        line-height: 1.6;

        @include max-lines(4, 1.6);
      }
    }

    @include media-breakpoint-down(sm) {
      .Thumbnail {
        .Tag {
          bottom: 30px;
        }
      }

      .Content {
        .Category {
          font-size: $font-size-base;
          margin-bottom: px(2);
        }

        .Title {
          font-size: $font-size-h3;
          margin-bottom: px(1);
        }

        .Excerpt {
          font-size: $font-size-base;

          @include max-lines(3, 1.6);
        }
      }
    }
  }

  &:hover {
    .Title {
      color: color(primary);
    }
  }
}
