@import '@assets/styles/abstracts';

.NewsletterSignupBlock {
  position: relative;

  padding: px(10) px(8) px(2);
  margin-bottom: px(6);

  .Content {
    width: 50%;

    .Title {
      font-size: $font-size-h2;
      font-weight: $font-weight-black;
      line-height: 1.3;
      text-transform: uppercase;
      color: color(primary);
    }

    .Description {
      font-size: $font-size-h3;
      font-weight: $font-weight-semibold;
      line-height: 1.4;
    }

    .Email {
      min-width: 75%;
      width: fit-content;
    }

    .Error {
      font-weight: $font-weight-semibold;
      color: color(error);
      opacity: 0;

      margin: 0;
      margin-top: px(2);

      @include transition(opacity, sm);

      &.Show {
        opacity: 1;
      }
    }

    .Message {
      font-weight: $font-weight-semibold;
      color: color(success);
      opacity: 0;

      margin: 0;
      margin-top: px(2);

      @include transition(opacity, sm);

      &.Show {
        opacity: 1;
      }
    }

    .Checkbox {
      margin-top: px(2);
      width: 66%;
    }
  }

  .BackgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;

    img {
      object-fit: cover;
      z-index: -2;
    }
  }

  .AccentImage {
    position: absolute;
    top: px(12);
    left: 25%;
    right: px(8);
    bottom: 0;
    margin-bottom: -#{px(7)};

    pointer-events: none;

    img {
      object-fit: cover;
      z-index: -1;
    }
  }

  @include media-breakpoint-down(lg) {
    .Content {
      width: 75%;
    }
  }

  @include media-breakpoint-down(md) {
    padding: px(8) px(6) px(2);

    .Content {
      width: 100%;

      .Title {
        font-size: $font-size-xxl;
      }

      .Description {
        font-size: $font-size-lg;
      }
    }

    .AccentImage {
      top: 50%;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: px(4) px(3) px(2);

    .Content {
      .Title {
        font-size: $font-size-xl;
      }

      .Description {
        font-size: $font-size-md;
      }

      .Email {
        width: 100%;
        font-size: $font-size-xs;
      }

      .Checkbox {
        width: 100%;
      }
    }

    .AccentImage {
      display: none;
    }
  }
}
