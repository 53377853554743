@import '@assets/styles/abstracts';

.NewsListItem {
  display: flex;
  flex-direction: column;

  .Thumbnail {
    display: flex;

    align-items: center;
    position: relative;
    overflow: hidden;

    @include aspect(100%, '3:2');

    img {
      object-fit: cover;
    }
  }
  .Content {
    box-sizing: border-box;

    .Title {
      overflow: hidden;
      @include max-lines(2, $headings-line-height);
    }
  }

  &.sm {
    .Content {
      padding: px(1.5) px(2) px(1) px(2);

      .Title {
        font-size: $font-size-lg;
        margin-bottom: px(1);
      }
    }
  }

  &.md {
    .Content {
      padding: px(3) px(2) px(2) px(3);

      .Title {
        font-size: $font-size-h3;
        margin-bottom: px(1);
      }
    }
  }

  &.lg {
    .Content {
      padding: px(5) px(2) px(4);

      .Title {
        font-size: $font-size-h2;
        text-transform: uppercase;

        margin-bottom: px(1);
      }
    }
  }
}
