@import '@assets/styles/abstracts';

.PrimaryHeader {
  position: relative;
  padding: 96px px(8);
  height: 550px;

  display: flex;
  align-items: flex-end;

  @include media-breakpoint-down(sm) {
    padding: px(8) px(4);
    height: 350px;
  }

  @include media-breakpoint-down(xs) {
    padding: px(4) px(3);
    height: 250px;
  }

  .BackgroundImage {
    object-fit: cover;
    z-index: -1;
  }

  .Content {
    width: 100%;

    .Title {
      width: 50%;
      margin-top: auto;
      color: color(white);

      @include media-breakpoint-down(lg) {
        width: 75%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        font-size: $font-size-h2;

        @include max-lines(2, $headings-line-height);
      }

      @include media-breakpoint-down(xs) {
        font-size: $font-size-h3;
      }
    }

    .Description {
      color: color(white);
      font-size: $font-size-h2;
      line-height: $headings-line-height;
      margin: 0;
      white-space: pre-wrap;
      width: 66%;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        font-size: $font-size-h3;

        @include max-lines(2, $headings-line-height);
      }

      @include media-breakpoint-down(xs) {
        font-size: $font-size-h4;
      }
    }

    .Button {
      padding: px(2) px(3);
      font-weight: $font-weight-semibold;
      line-height: $headings-line-height;
      margin-top: px(10);

      @include media-breakpoint-down(sm) {
        margin-top: px(5);
      }

      @include media-breakpoint-down(xs) {
        margin-top: px(3);
        width: 100%;
      }
    }
  }
}
