@import '@assets/styles/abstracts';

.ArticleProperty {
  font-weight: $font-weight-medium;

  margin: 0;

  &.primary {
    color: color(support, dark);
  }

  &.secondary {
    color: color(white);

    .Icon {
      color: color(primary);
    }
  }

  &.xs {
    font-size: $font-size-xs;
  }

  &.sm {
    font-size: $font-size-sm;
  }

  &.md {
    font-size: $font-size-md;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-sm;
    }
  }

  &.lg {
    font-size: $font-size-lg;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-sm;
    }
  }

  &.xl {
    font-size: $font-size-h3;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-sm;
    }
  }

  .Icon {
    margin-right: px(1);
  }
}
