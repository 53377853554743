@import '@assets/styles/abstracts';

.ListWrapper {
  .Content {
    margin-bottom: 60px;
  }

  .Header {
    margin-bottom: px(3);
  }

  .Button {
    margin: 0 auto;
    font-weight: $font-weight-semibold;
    padding: px(2) px(3);
  }
}
