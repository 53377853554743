@import '@assets/styles/abstracts';

.InputWithSubmit {
  display: flex;
  flex-direction: row;

  border: 1px solid color(primary);
  background-color: color(white);

  font-size: $font-size-base;

  input {
    display: block;
    flex: 1;
    box-sizing: border-box;
    border: none;
    outline: none;

    color: color(primary);
    font-size: em(16px);

    padding: 15px 5px 15px 15px;
    width: 100%;
    min-width: fit-content;

    &::placeholder {
      color: color(primary, light);
      font-size: em(14px);
      font-style: italic;
      font-weight: $font-weight-light;
    }
  }

  .Submit {
    padding: px(2);
    font-size: em(14px);
    font-weight: $font-weight-medium;
    line-height: $headings-line-height;
    margin: px(1);
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .Submit {
      width: 100%;
      margin: 0;
    }
  }
}
