@import '@assets/styles/abstracts';

.HomePage {
  .SearchBlock {
    margin-top: px(10);
  }

  .Tips {
    margin-top: px(10);
  }

  .Newsletter {
    margin-top: px(4);
  }

  .News {
    margin-top: px(16);
  }
}
